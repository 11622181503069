var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"container",staticClass:"position-relative"},[_c('div',{staticClass:"w-100 multiFilter px-2"},[(_vm.singleSelect && _vm.selectedText)?_c('div',{staticClass:"quizell-tags d-flex flex-wrap"},[_c('div',{staticClass:"tag px-1 m-1 d-flex align-items-center justify-content-between"},[_vm._v(" "+_vm._s(_vm.selectedText)+" "),_c('i',{staticClass:"fa fa-times pl-2 cross-icon",attrs:{"aria-hidden":"true"},on:{"click":function($event){_vm.selectedText = null}}})])]):_vm._e(),_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchText),expression:"searchText"}],ref:"searchInput",staticClass:"filterSearch w-100",attrs:{"type":"text","disabled":_vm.isLoading},domProps:{"value":(_vm.searchText)},on:{"focus":_vm.onSearchboxFocus,"blur":_vm.onSearchboxBlur,"input":function($event){if($event.target.composing)return;_vm.searchText=$event.target.value}}}),(_vm.isLoading)?_c('b-spinner',{staticStyle:{"color":"#4d1b7e"},attrs:{"small":""}}):_vm._e()],1)]),((!_vm.singleSelect) && _vm.listActive)?_c('ul',{staticClass:"popover-list"},[(_vm.searchText && _vm.addInList)?_c('li',{staticClass:"popover-item cursor-pointer",on:{"click":() => {
         
          if(!_vm.selected.some(item=>item.title==_vm.searchText))
          {
            _vm.selected.push({ title: _vm.searchText });
           _vm.$emit('add',{ title: _vm.searchText });
           _vm.searchText=''
          }
          _vm.listActive = false;
        }}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fa fa-plus-circle",staticStyle:{"color":"rgb(183, 181, 181)"},attrs:{"aria-hidden":"true"}}),_c('div',{staticClass:"span mx-2 font-weight-bold"},[_vm._v("Add")]),_vm._v(" \""+_vm._s(_vm.searchText)+"\" ")])]):_vm._e(),(_vm.filteredItems.length > 0)?_vm._l((_vm.filteredItems),function(item){return _c('li',{key:item.id,staticClass:"popover-item"},[_c('b-form-checkbox',{staticClass:"checkbox cursor-pointer",attrs:{"value":item},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_vm._v(" "+_vm._s(item.title))])],1)}):(_vm.items.length <= 0)?[_c('li',{staticClass:"popover-item"},[_vm._v("The item list remained empty.")])]:(_vm.items.length > 0 && _vm.filteredItems.length <= 0)?_c('li',{staticClass:"popover-item"},[_vm._v(" This item does not exist yet. ")]):_vm._e()],2):(_vm.listActive)?_c('ul',{staticClass:"popover-list cursor-pointer"},[(_vm.searchText && _vm.addInList)?_c('li',{staticClass:"popover-item",on:{"click":() => {
          _vm.$emit('add',{ title: _vm.searchText });
          _vm.selectedText= _vm.searchText
          _vm.listActive = false;
          _vm.searchText='';
        }}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fa fa-plus-circle",staticStyle:{"color":"rgb(183, 181, 181)"},attrs:{"aria-hidden":"true"}}),_c('div',{staticClass:"span mx-2 font-weight-bold"},[_vm._v("Add")]),_vm._v(" \""+_vm._s(_vm.searchText)+"\" ")])]):_vm._e(),(_vm.filteredItems.length > 0)?_vm._l((_vm.filteredItems),function(item,index){return _c('li',{key:index,staticClass:"popover-item"},[_c('b-form-radio',{staticClass:"radio-button cursor-pointer",attrs:{"name":"all","value":item.title},on:{"input":()=>{
            _vm.$emit('input',_vm.selectedText)
            _vm.listActive = false
            }},model:{value:(_vm.selectedText),callback:function ($$v) {_vm.selectedText=$$v},expression:"selectedText"}},[_vm._v(_vm._s(item.title))])],1)}):(_vm.items.length <= 0)?[_c('li',{staticClass:"popover-item"},[_vm._v("The item list remained empty.")])]:(_vm.items.length > 0 && _vm.filteredItems.length <= 0)?_c('li',{staticClass:"popover-item"},[_vm._v(" This item does not exist yet. ")]):_vm._e()],2):_vm._e(),(!_vm.singleSelect)?_c('div',{staticClass:"quizell-tags d-flex flex-wrap"},_vm._l((_vm.selected),function(item,index){return _c('div',{key:index,staticClass:"tag px-1 m-1 d-flex align-items-center justify-content-between"},[(item.title)?[_c('span',{staticStyle:{"width":"fit-content","overflow":"hidden","text-overflow":"ellipsis","text-wrap":"nowrap"}},[_vm._v(" "+_vm._s(item.title))]),_c('i',{staticClass:"fa fa-times pl-2 cross-icon",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.remove(index)}}})]:_vm._e()],2)}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }